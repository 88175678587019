<template>
  <el-container>
    <el-aside :style="{width:isCollapse ? 'auto' :'200px'}">
      <sider :isCollapse="isCollapse" :menu="menu"></sider>
    </el-aside>
    <el-main>
      <el-header height="60px" class="l-header">
      <div class="left">
        <i class="el-icon-s-fold" style="font-size: 1.5em;" @click="isCollapse=!isCollapse"></i>
<!--        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>频道管理</el-breadcrumb-item>
          <el-breadcrumb-item>招聘信息</el-breadcrumb-item>
        </el-breadcrumb>-->
      </div>
        <div class="center">
          <el-autocomplete
              popper-class="my-autocomplete"
              v-model="state"
              :fetch-suggestions="querySearch"
              placeholder="请输入菜单名称"
              @select="handleSelect">
            <i
                class="el-icon-search el-input__icon"
                slot="prefix"
                @click="handleIconClick">
            </i>
            <template slot-scope="{ item }">
              <div class="name" >{{ item.value }}</div>
              <span class="addr">{{ item.address }}</span>
            </template>
          </el-autocomplete>
        </div>
      <div class="right">
        <el-avatar :src="user.avatar" style="background: initial"></el-avatar>
        <el-dropdown>
          <span>{{user.name}}</span> <i class="el-icon-arrow-down" style="margin-right: 15px"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item><a href="#/setting/repassword">修改密码</a></el-dropdown-item>
            <el-dropdown-item><span @click="signout">退出</span></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      </el-header>
      <el-header height="50px" class="l-tabs">
        <i class="lgq-jiantou_xiangzuoliangci" @click="clickScreenLeft"></i>
        <div class="l-tabs-main" ref="tabs">
          <span class="l-tabs-item l-tabs-active" v-for="(item,index) in $store.state.label_page" :key="index" :class="{'l-tabs-active':$store.state.active_label==item.title}" @click="tabActive=index">
            <span @click.stop="$router.push(item.path)">{{item.title}} </span>
            <i class="el-icon-close" @click="$delete(tabs,index)"> </i>
          </span>
        </div>
        <i class="lgq-jiantou_xiangyouliangci"  @click="clickScreenRight"></i>
      </el-header>
      <div class="l-main">
        <router-view />
      </div>
    </el-main>
  </el-container>
</template>
<script>
import sider from "@/components/sider";
import { mapMutations,mapState  } from 'vuex'
import request from  '@/utils/request.js'
export default {
  name: "layout",
  data(){
     return {
       restaurants: [],
       state: '',
       menu:[],
       tabActive:0,
       isCollapse:false,
       user:{}
     }
  },

  computed:{
    ...mapState
  },
  watch:{
     $route(to,from){

     }
  },
  mounted() {

    this.get_user_info();
    this.get_menu()
  },
  methods:{
    querySearch(queryString, cb) {
      let results = [];
      cb(results);
    },
    get_menu(){
        request({
          url:'/login/auth',
          method:'get'
        }).then((res)=>{
          this.menu = res.data;
        }).catch((err)=>{
          this.$message.error(err.msg)
        })
    },
    get_user_info(){
        return request({
          url:'/login/userInfo',
          method:'get'
        }).then(({data})=>{
          console.log(data);
          this.user = data;
        })
    },
    signout(){
      return request({
        url:'/login/signout',
        method:'get'
      }).then(({data})=>{
        this.get_user_info()
      })
    },
    handleSelect(item) {
      console.log(item);
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    clickScreenLeft(event){
      const container = this.$refs.tabs;
      container.scrollLeft= container.scrollLeft-100
    },
    clickScreenRight(event){
       const container = this.$refs.tabs;
       container.scrollLeft= container.scrollLeft+100
    }
  },
  components:{
    sider
  }
}
</script>

<style scoped lang="less">
/deep/.el-menu-item, /deep/.el-submenu__title {
  font-family: arial, sans-serif;
  font-style: normal;
  font-weight: bolder;
  letter-spacing: 2px;
}
.el-menu{
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.el-main{
  background-color: #f5f5f5;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  .l-header{
    background-color: #ffffff;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      display: flex;
      align-items: center;
      &>i{
         margin-right: 1em;
      }

    }
    .center{
      flex:1;
      padding: 0 1em;
      /deep/.el-input__inner{
        width: auto;
        border:none!important;
      }
    }
    .right{
      display: flex;
      align-items: center;
      padding:0  1em;
      box-sizing: border-box;
      border-left: 1px #f5f5f5 solid;
    }
  }
  .l-tabs{
    line-height: 50px;
    background-color: #ffffff;
    display: flex;
    &>i{
      font-size: 2em;
      color:#888;
      cursor: pointer;
      width:50px;
      text-align: center;
      &:nth-child(1){
        border-right:1px solid #f5f5f5;
      }
      &:nth-child(3){
        border-left:1px solid #f5f5f5;
      }
    }
    .l-tabs-main{
      flex:1;
       white-space: nowrap;
       overflow: hidden;
      .l-tabs-item{
        box-sizing: border-box;
        height: 100%;
        display: inline-block;
        padding:0 1em;
        cursor: pointer;
        border-right:1px solid #f5f5f5;
        position: relative;
      }
      .l-tabs-active{
        color:#0079fe;
        &:before{
          content: "";
          position: absolute;
          bottom: 0;
          background-color: #0079fe;
          height: 3px;
          width: 30%;
          left: 50%;
          transform: translateX(-50%);
        }

      }
    }
  }
  .l-main{
    padding:10px 10px;
    margin:10px 10px;
    box-sizing: border-box;
    height: calc(100vh - 115px);
    background-color: #ffffff;
    overflow: auto;
    /*滚动条宽 长,滚动条整体部分，其中的属性有width,height,background,border等。*/

  }
}
</style>
