import { render, staticRenderFns } from "./upload.vue?vue&type=template&id=ecf57f78&scoped=true"
import script from "./upload.vue?vue&type=script&lang=js"
export * from "./upload.vue?vue&type=script&lang=js"
import style0 from "./upload.vue?vue&type=style&index=0&id=ecf57f78&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecf57f78",
  null
  
)

export default component.exports