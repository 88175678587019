import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/components/layout.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Layout,
        meta:{
         title:'首页',
        },
        children: [
            {
                path: '/system/menu',
                name: 'admin_menu',
                meta:{
                 title:'菜单管理',
                },
                component: () => import('../views/system/menu/listing.vue')
            },
            {
                path: '/system/menu/form',
                component: () => import('../views/system/menu/forms.vue')
            },
            {
                path: '/system/role',
                name: 'admin_role',
                component: () => import('../views/system/role/listing.vue')
            },
            {
                path: '/system/role/form',
                component: () => import('../views/system/role/forms.vue')
            },
            {
                path: '/system/user',
                name: 'admin_user',
                component: () => import('../views/system/user/listing.vue')
            },
            {
                path: '/system/user/form',
                component: () => import('../views/system/user/forms.vue')
            },
            {
                path:'channel/listing',
                component:()=>import('../views/channel/listing.vue')
            },
            {
                path:'channel/create',
                component:()=>import('../views/channel/components/create.vue')
            },
            {
                path:'channel/attr',
                component:()=>import('../views/channel/attr.vue')
            },
            {
                path:'channel/item/:channel_id',
                prop:true,
                component:()=>import('../views/channel/item.vue')
            },
            {
                path:'/file',
                prop:true,
                component:()=>import('../views/file.vue')
            },
            {
                path:'/ad/position',
                prop:true,
                component:()=>import('../views/ad/position/listing.vue')
            },
            {
                path:'/ad/position/form',
                prop:true,
                component:()=>import('../views/ad/position/forms.vue')
            },
            {
                path:'ad/listing',
                prop:true,
                meta:{
                 title:'广告管理',
                },
                component:()=>import('../views/ad/listing.vue')
            },
            {
                path:'/ad/form',
                prop:true,
                component:()=>import('../views/ad/forms.vue')
            },
            {
                path:'/about',
                prop:true,
                meta:{
                    title:'关于我们',
                   },
                component:()=>import('../views/about.vue')
            },
            {
                path:'/message/listing',
                prop:true,
                meta:{
                 title:'委托列表',
                },
                component:()=>import('../views/message/listing.vue')
            },
            {
                path:'/message/form',
                prop:true,
                meta:{
                  title:'委托列表',
                },
                component:()=>import('../views/message/forms.vue')
            },
            {
                path:'/message/feedback/listing',
                prop:true,
                meta:{
                    title:'反馈列表',
                },
                component:()=>import('../views/message/feedback/listing.vue')
            },
            {
                path:'/message/feedback/form',
                prop: true,
                meta:{
                  title:'反馈列表',
                },
                component:()=>import('../views/message/feedback/forms.vue')
            },
            {
                path:'/setting/license',
                prop:true,
                meta:{
                 title:'用户协议',
                },
                component:()=>import('../views/setting/license.vue')
            },
            {
                path:'/setting/base',
                prop:true,
                meta:{
                 title:'系统设置',
                },
                component:()=>import('../views/setting/base.vue')
            },
            {
                path:'/setting/repassword',
                prop:true,
                meta:{
                 title:'修改密码',
                },
                component:()=>import('../views/setting/password.vue')
            },
            {
                path:'/setting/wechat',
                prop: true,
                meta:{
                 title:'微信配置',
                },
                component:()=>import('../views/setting/wechat.vue')
            },
            {
                path:'/setting/sitemap',
                prop:true,
                meta:{
                  title:'站点地图',
                },
                component:()=>import('../views/setting/sitemap.vue')
            },
            {
                path:'/user/listing',
                prop:true,
                meta:{
                 title:'会员列表',
                },
                component:()=>import('../views/user/listing.vue')
            },
            {
                path:'/user/form',
                prop:true,
                meta:{
                    title:'会员列表',
                },
                component:()=>import('../views/user/forms.vue')
            },
            {
                path:'/log/user_login',
                prop:true,
                meta:{
                 title:'登陆日志',
                },
                component:()=>import('../views/log/user_login.vue')
            },
            {
                path:'/log/system_user_login',
                prop:true,
                component:()=>import('../views/log/system_user_login.vue')
            },
            {
                path:'/article/listing',
                component:()=>import('../views/article/listing.vue')
            },
            {
                path:'/article/form',
                component:()=>import('../views/article/forms.vue')
            },
            {
                path:'/article/category/listing',
                component:()=>import('../views/article/category/listing.vue')
            },
            {
                path:'/article/category/form',
                component:()=>import('../views/article/category/forms.vue')
            },
            {
                path:'/setting/nav',
                prop:true,
                meta:{
                 title:'导航管理',
                },
                component:()=>import('../views/setting/nav.vue')
            },
            {
                path:'/setting/app',
                prop:true,
                meta:{
                    title:'APP管理',
                },
                component:()=>import('../views/setting/app.vue')
            },
            {
                path:'/setting/where',
                prop:true,
                meta:{
                    title: '条件搜索管理',
                },
                component:()=>import('../views/setting/where.vue')
            },
            {
                path:'/setting/region',
                prop:true,
                meta:{
                 title:'区域管理',
                },
                component:()=>import('../views/setting/region.vue')
            },
            {
                path:'/setting/seo',
                prop:true,
                meta:{
                    title:'SEO管理',
                },
                component:()=>import('../views/setting/seo.vue')
            },
            {
                path: '/house/listing',
                prop:true,
                meta:{
                 title:'厂房管理',
                },
                component:()=>import('../views/house/listing.vue')
            },
            {
                path:'/house/form',
                prop: true,
                component:()=>import('../views/house/forms.vue')
            },
            {
                path: '/office/listing',
                prop:true,
                meta:{
                 title:'写字楼管理',
                },
                component:()=>import('../views/office/listing.vue')
            },
            {
                path:'/office/form',
                prop: true,
                component:()=>import('../views/office/forms.vue')
            },
            {
                path: '/land/listing',
                prop:true,
                meta:{
                 title:'土地管理',
                },
                component:()=>import('../views/land/listing.vue')
            },
            {
                path:'/land/form',
                prop: true,
                component:()=>import('../views/land/forms.vue')
            },
            {
                path:'/house/label/listing',
                prop: true,
                meta:{
                 title:'标签列表',
                },
                component:()=>import('../views/house/label/listing.vue')
            },
            {
                path:'/house/label/form',
                prop: true,
                component:()=>import('../views/house/label/forms.vue')
            },
            {
                path:'/house/category/listing',
                prop: true,
                meta:{
                    title:'类型列表',
                },
                component:()=>import('../views/house/category/listing.vue')
            },
            {
                path:'/house/category/form',
                prop: true,
                component:()=>import('../views/house/category/forms.vue')
            },
            {
                path:'/chat/listing',
                prop: true,
                meta:{
                    title:'消息列表',
                },
                component:()=>import('../views/chat/listing.vue')
            },
            {
                path:'/chat/form',
                prop: true,
                meta:{
                    title:'消息列表',
                },
                component:()=>import('../views/chat/forms.vue')
            },
            {
                path:'/chat/system/listing',
                prop: true,
                meta:{
                    title:'系统消息',
                },
                component:()=>import('../views/chat/system/listing.vue')
            },
            {
                path:'/chat/system/form',
                prop: true,
                meta:{
                    title:'系统消息',
                },
                component:()=>import('../views/chat/system/forms.vue')
            },
            {
                path:'/chat/system/add',
                prop: true,
                meta:{
                    title:'系统消息',
                },
                component:()=>import('../views/chat/system/add.vue')
            },
            {
                path:'/shop/listing',
                prop: true,
                meta:{
                    title:'门店列表',
                },
                component:()=>import('../views/shop/listing.vue')
            },
            {
                path:'/shop/form',
                prop: true,
                meta:{
                    title:'门店详情',
                },
                component:()=>import('../views/shop/forms.vue')
            },
            {
                path:'/shop/apply/listing',
                prop: true,
                meta:{
                    title:'经纪人列表',
                },
                component:()=>import('../views/shop/apply/listing.vue')
            },
            {
                path:'/shop/apply/form',
                prop: true,
                meta:{
                    title:'经纪人详情',
                },
                component:()=>import('../views/shop/apply/forms.vue')
            },
            {
                path: '/link/listing',
                prop:true,
                meta:{
                    title:'友情链接管理',
                },
                component:()=>import('../views/link/listing.vue')
            },
            {
                path: '/link/form',
                prop:true,
                meta:{
                    title:'友情链接管理',
                },
                component:()=>import('../views/link/forms.vue')
            },
            {
                path: '/house/listinga',
                prop:true,
                meta:{
                    title:'厂房管理',
                },
                component:()=>import('../views/house/listinga.vue')
            }
            // {
            //     path:'*',
            //     prop:true,
            //     component:()=>import('../views/404.vue')
            // }
        ]
    },
    {
        path:'/login',
        component:()=>import('../views/login.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
