import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './router/permission'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import upload from  './components/upload.vue';
import breadcrumb from './components/breadcrumb.vue';
import success from './components/success.vue';
Vue.component('upload',upload);
Vue.component('breadcrumb',breadcrumb);
Vue.component('success',success);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VXETable)
 new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

