import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    label_page:[
      {
        title:'',
        path:'/product'
      }
    ],
    active_label:'测试'
  },
  getters: {
  },
  mutations: {
    /**
     * 设置标签页
     * @param commit
     * @param state
     */
    __addLabel: function (state:any,label:any) {
      const hasItem =  state.label_page.some((item: any) => item.title==label.title)
      if(!hasItem){
        state.label_page.push(label)
      }
    },
  },
  actions: {

    /**
     *
     */
    __setLabel(){

    }
  },
  modules: {
  }
})
