import request from "@/utils/request";
export default {
    data() {
        return {
            active:'/'
        };
    },
    mounted(){
       /* this.menu_data = [
            {
                "id": "1",
                "title": "控制台",
                "icon": "el-icon-s-data",
                "router_path": "/homes"
            },
            {
                "id": "2",
                "title": "商品管理",
                "icon": "el-icon-s-check",
                "router_path": "",
                "children":[
                    {
                        "id":"2",
                        "title": "商品列表",
                        "router_path": "/product",
                    },
                    {
                        "id": "3",
                        "title": "商品规格",
                        "router_path": "/product/sku",
                    },
                    {
                        "id":"4",
                        "title": "商品分类",
                        "router_path": "/product/category",
                    },
                    {
                        "id":"5",
                        "title": "商品评论",
                        "router_path": "/product/reply",
                    }
                ]
            },
  /!*          {
                "id": "3",
                "title": "门店管理",
                "icon": "el-icon-s-shop",
                "router_path": "",
                "children":[
                    {
                        "id": "3",
                        "title": "门店列表",
                        "router_path": "/store",
                    },
                    {
                        "id": "3",
                        "title": "门店分类",
                        "router_path": "/store/category",
                    }
                ]
            },*!/
            {
                "id": "112",
                "title": "频道管理",
                "icon": "el-icon-s-data",
                "router_path": "/channel",
                "children":[
                    {
                        "id": "113",
                        "title": "频道列表",
                        "router_path": "/channel",
                    },
                    {
                        "id": "113324",
                        "title": "招聘信息",
                        "router_path": "/channel/jobs",
                    }
                ]
            },
            /!*{
                "id": "115",
                "title": "日志",
                "icon": "el-icon-s-data",
                "router_path": "/homessss",
                "children":[
                    {
                        "id": "113",
                        "title": "短信发送",
                        "router_path": "/homesss",
                    },
                    {
                        "id": "114",
                        "title": "操作日志",
                        "router_path": "/homessss",
                    }
                ]
            },*!/
            {
                "id": "115",
                "title": "订单管理",
                "icon": "el-icon-s-data",
                "router_path": "/homesssssssss",
                "children":[
                    {
                        "id": "113",
                        "title": "商品订单",
                        "router_path": "/homesss",
                    }

                ]
            },
            {
                "id": "115",
                "title": "财务",
                "icon": "el-icon-s-data",
                "router_path": "/homessssss",
                "children":[
                    {
                        "id": "113",
                        "title": "提现申请",
                        "router_path": "/finance/extract",
                    },
                    {
                        "id": "114",
                        "title": "充值记录",
                        "router_path": "/finance/recharge",
                    },
                    {
                        "id": "114",
                        "title": "用户账单",
                        "router_path": "/finance/extract",
                    }
                ]
            },
            {
                "id": "116",
                "title": "权限管理",
                "icon": "el-icon-s-data",
                "router_path": "/auth",
                "children":[
                    {
                        "id": "113",
                        "title": "管理员",
                        "router_path": "/system/user",
                    },
                    {
                        "id": "114",
                        "title": "菜单管理",
                        "router_path": "/system/menu",
                    },
                    {
                        "id": "114",
                        "title": "角色管理",
                        "router_path": "/system/role",
                    },
                    {
                        "id": "114",
                        "title": "部门管理",
                        "router_path": "/system/department",
                    }
                ]
            },
            {
                "id": "1168",
                "title": "设置",
                "icon": "el-icon-setting",
                "router_path": "/setting",
                "children":[
                    {
                        "id": "113",
                        "title": "系统设置",
                        "router_path": "/setting/base",
                    },

                    {
                        "id": "114",
                        "title": "运费模板",
                        "router_path": "/setting/shipping_templates",
                    }
                ]
            },
            {
                "id": "4",
                "title": "会员管理",
                "icon": "el-icon-s-shop",
                "router_path": "",
                "children":[
                    {
                        "id": "3",
                        "title": "会员等级",
                        "router_path": "/member/level",
                    },
                    {
                        "id": "3",
                        "title": "会员列表",
                        "router_path": "/member",
                    },
                    {
                        "id": "3",
                        "title": "会员标签",
                        "router_path": "/member/label",
                    },

                ]
            },
            {
                "id": "32423",
                "title": "内容管理",
                "icon": "el-icon-wallet",
                "router_path": "/content",
                "children":[
                    {
                        "id": "113",
                        "title": "关于我们",
                        "router_path": "/about",
                    },

                    {
                        "id": "114",
                        "title": "用户协议",
                        "router_path": "/user_license",
                    },
                    {
                        "id": "114",
                        "title": "授权协议",
                        "router_path": "/agreement",
                    },
                    {
                        "id": "114",
                        "title": "法律责任",
                        "router_path": "/law",
                    }
                ]
            },
            {
                "id": "1168",
                "title": "反馈系统",
                "icon": "el-icon-message",
                "router_path": "/feedback",
            },

        ]*/
        this.active = this.$route.path

    },
    props: {
        isCollapse: {
            type: Boolean,
            default: false
        },
        menu:{
            type:Array,
            default:()=>{
                return  [];
            }
        }
    },
    render:function(h){
        const that = this;
        function submenu(title,path,icon,children=[]){

            return   h('el-submenu',
                {props:{index:path.toString()}},
                [
                    h('template', {slot:'title'}, [h('i',{class:icon}),h('span',title)]),
                    ...children
                ])
        }
        function menuItem(title,path,icon){
            return   h('el-menu-item',{
                props:{
                    index:path.toString()
                }
            },[h('i',{class:[icon]}),h('span',{slot:'title'},title)])
        }
        function create_node(nodes){
            let  nodelist = [];
            nodes.forEach((item)=>{
                let title = item.name;
                let path =  (item.route_path  === '' || item.route_path == '/') ? item.id : item.route_path;
                let icon = item.icon;
                if(Array.isArray(item.children) && item.children.length > 0){
                    nodelist.push(submenu(title,path,icon,create_node(item.children))) ;
                }else{
                    nodelist.push(menuItem(title,path,icon))
                }
            })

            return nodelist;
        }
        let res = create_node(this.menu);
        return h('el-menu',{
            style:{
              height:'100vh'
            },
                props:{
                backgroundColor:'#282c34',
                textColor:'#fff',
                activeTextColor:'#009688',
                defaultActive:that.active,
                collapse:that.isCollapse,
                router:true,
                uniqueOpened:true
            }
        },[...res])
    }
}
