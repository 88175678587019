<template>
    <el-result icon="success" v-show="isshow" :title="title" :subTitle="subTitle">
        <template slot="extra">
            <el-button type="primary" size="medium" @click.native="handleClick">继续</el-button>
            <el-button type="info" size="medium" @click.native="$router.go(-1)">返回</el-button>
        </template>
    </el-result>
</template>

<script>
    export default {
        name: "success",
        props:['title','subTitle','isshow'],
        methods:{
            handleClick(){
                this.$emit("onChange")
            }
        }
    }
</script>

<style scoped>

</style>
