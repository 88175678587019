import router from "@/router/index";
const white = [
  '/login'
];
router.beforeEach((to, from,next) => {
 const token =  localStorage.getItem('token');
 if(to?.meta?.title){
   window.document.title = to?.meta?.title;
  // console.log("下一个页面",to?.meta?.title)
}
  if(!white.includes(to.fullPath)){
    if(!token){
        next('/login')
    }
   
  }
  // 放行白名单
  next();
})
router.afterEach((to, from)=>{
})
