<template>
    <el-breadcrumb separator-class="el-icon-arrow-right" style="margin:20px;font-size:16px">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item><a href="javascript:history.back(-1)">列表</a></el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
    export default {
        name: "breadcrumb"
    }
</script>

<style scoped>

</style>
