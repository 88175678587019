import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import router from "../router";
import {Loading} from 'element-ui';
import {Base64} from 'js-base64';
import sha1 from "sha1";
// create an axios instance
const service = axios.create({
    baseURL: '/admin/v1', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

function getSign(){

    let devicetype = 'pc';
    let apiversion = 'v1';
    let flag = '&$admin$&';
    let apiKey ="2c*3b#!4ebs+/ad-*da548d/b8c*94164a151+7b3d?7d1a";
    let apiSecret ="94d7fc4!65*34a15aec4fefff+52Pos-02339105!=-8c0";
    let time = parseInt(new Date().getTime() / 1000) + '';

    let sign = Base64.encode(sha1(devicetype+apiversion+time)+flag+devicetype+flag+apiversion+flag+time+flag+sha1(apiKey+time+apiSecret));
    return {sign,time};
}
let loading;
// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        const m = getSign()
        config.headers['token'] = localStorage.getItem('token');
        config.headers['sign'] = m.sign;
        config.headers['timestamp'] = m.time;
        const {data, headers} = config;
        /*form data 数据转换*/
        if (headers.hasOwnProperty('Content-Type') && headers['Content-Type'].includes('multipart/form-data')) {
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (Array.isArray(data[key])) {
                    data[key].forEach((file, i) => {
                        formData.append(`${key}[${i}]`, file);
                    })
                } else {
                    formData.append(key, data[key])
                }
            })
            config.data = formData;
        }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)
// response interceptor
service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        const {data, status} = error.response;
        let error_code =[5001,5002,5003]
        if(error_code.includes(data.code))
        {
            localStorage.clear();
            location.reload();
        }
        if (status == 500) {
            return Promise.reject({
                    msg: '服务器错误'
                }
            )
        }
        return Promise.reject(data)
    }
)

export default service;
