<template>
  <div>
    <input type="file"   ref="file" @change="FileChange($event)" multiple="multiple" style="display:none" >

      <template v-for="(image,index)  in preview" >
        <span  @click="$refs.file.click()">
            <slot name="preview" v-bind:image="image">

               <el-image fit="contain" lazy :style="{height:height,width:width}" :src="image"></el-image>
            </slot>
        </span>
      </template>
    <div class="upload" v-show="(!multiple && !preview.length) || multiple" :style="{height,width,lineHeight:height}" >
      <i class="el-icon-upload" @click="$refs.file.click()"  ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "upload",
  props:{
    value:{
      default:''
    },
    width:{
      type:String,
      default:"200px"
    },
    multiple:{
      type:Boolean,
      default:false
    },
    height:{
      type:String,
      default:"100px"
    },
  },
  data(){
    return {
      file:[]
    }
  },
  computed:{
      preview(){
        const that =this;
        let view = [];
        that.file.forEach((file,i)=>{

          if(file instanceof  File){
            view[i] =  window.URL.createObjectURL(file);
          }else{
            view[i] =file;
          }

        })
        return view
      }
  },
  watch:{
     value(){
       this.againinage();
     }
  },
  mounted() {
   this.againinage();
  },
  methods:{
    againinage(){
      if(this.value.length){
        if(this.value  instanceof  Array){
          this.file  = this.value;
        }else{
          this.file  = [this.value];
        }
      }
    },
    FileChange(event){
      const that =this;
      let i = that.file.length;
       if(!this.multiple){
         i  = 0;
       }
       that.$set(this.file,i,event.target.files[0])
      let file = that.file;
      if(!this.multiple){
        file =  that.file[0]
      }
       that.$emit('input',file);
    }
  }
}
</script>

<style lang="less" scoped>
  .upload{
    border:1px  dashed  #cdcdcd;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-size: 5em;
    overflow: hidden;
    position: relative;
    display: inline-block;
    .cover{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }
  }
</style>